import {
  Grid,
  Typography,
  Paper,
  IconButton,
  InputBase,
  Divider,
} from "@mui/material";
import { Clear, Search } from "@mui/icons-material";
import { Translate } from "../i18n/translate";

const SearchBox = (props) => {
  const handleFormSubmit = (e) => {
    e.preventDefault();
  };

  const search = (e) => {
    const searchText = e.target.value;
    if (searchText === "") {
      return;
    }
    if (sessionStorage.getItem("isPremiumSubscriber") === "true") {
      props.callback(searchText);
    } else {
      if (e.key === "Enter") {
        props.callback(searchText);
      }
    }
  };
  const clear = () => {
    // const inputElement = document.getElementById("search-box");
    // inputElement.value = "";
    props.onCancel();
  };

  const makeCall = () => {
    const searchText = document.getElementById("search-box").value;
    if (searchText === "") {
      return;
    }
    props.callback(searchText);
  };

  const handleCancel = () => { 
    const inputElement = document.getElementById("search-box");
    inputElement.value = "";
    props.onCancel();
  };

  return (
    <Grid container>
      <Grid item key={2} lg={12}>
        <Paper
          component="form"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            mb: 1,
          }}
          onSubmit={handleFormSubmit}
        >
          <IconButton
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
            onClick={makeCall}
          >
            <Search />
          </IconButton>

          <InputBase
            id="search-box"
            onKeyUp={search}
            sx={{ ml: 1, flex: 1 }}
            placeholder={Translate.translate("SEARCH")}
          />
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <IconButton
            sx={{ p: "10px" }}
            aria-label="search"
            onClick={handleCancel}
          >
            <Clear fontSize="small" />
          </IconButton>
        </Paper>

        {sessionStorage.getItem("isPremiumSubscriber") !== "true" && (
          <Typography variant="body2" style={{ color: "#858383" }}>
            {Translate.translate("PREMIUM_SEARCH_ADVANTAGE")}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default SearchBox;
