import React from 'react';
import { Rocket } from '@mui/icons-material';
import { Translate } from '../i18n/translate';
import { Chip, Card, CardContent, Typography, Box } from '@mui/material';

const PremiumComponent = () => {
  return (
    <Box 
      display="flex" 
      justifyContent="center" 
      alignItems="center"   
      p={2}
    >
      <Card 
        sx={{
          
          textAlign: "center",
          boxShadow: 5,
          borderRadius: 3,
          p: 3,
          background: "linear-gradient(135deg, #e3f2fd, #ffffff)"
        }}
      >
        <CardContent>
          <Chip 
            icon={<Rocket />} 
            color="primary"  
            label={Translate.translate('PREMIUM_FEATURE')}
            sx={{ mb: 2, fontSize: "1rem", fontWeight: "bold" }}
          />
          <Typography variant="h5" fontWeight="bold" gutterBottom>
            {Translate.translate('PREMIUM_CONTENT_TITLE')}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {Translate.translate('PREMIUM_CONTENT_EXPLANATION')}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default PremiumComponent;
