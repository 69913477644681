import { styled } from "@mui/material/styles";
import { ButtonGroup, Button, Alert, TableRow, TableCell } from "@mui/material";
import devices from "../devices";
import QRCode from "qrcode.react";
import { UserService } from "../services/user.services";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Translate } from "../i18n/translate";
import { Android, Apple } from "@mui/icons-material";

const StyledInfo = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "flex-end",
  marginTop: theme.spacing(0),
  color: theme.palette.text.disabled,
}));

export default function DeviceCard(props) {
  function printQR(usr) {
    
    if (usr.user?.isActive) {
      return;
    }

    if (usr.account.owner === usr.user?._id) {
      return;
    }

    if (usr.user?.isDeviceRegistered === true) {
      return (
        <Button onClick={() => renewQRCode(usr.user?._id)}>
          {Translate.translate("SIGNIN_AGAIN")}
        </Button>
      );
    }
  }

  function renewQRCode(id) {
    UserService.renewQRCode(id)
      .then((result) => {
        props.callback();
      })
      .catch((error) => {});
  }

  function deviceName(usr) {
    if (usr?.deviceType === "iOS") {
      let device = devices.filter((item) => item.model === usr?.deviceName);
      if (device.length > 0) {
        return device[0].name;
      }
      return usr?.deviceName;
    } else {
      return usr?.deviceName;
    }
  }
  function actionButtons(usr) {
    if (usr.account.owner === usr.user?._id) {
      return (
        <Button variant="text" disabled>
          {Translate.translate("MAIN_ACCOUNT")}
        </Button>
      );
    }

    if (sessionStorage.getItem("isPremiumSubscriber") !== "true"){
      return <>{Translate.translate("PREMIUM_FEATURE")}</>
    }

    if (!usr.user?.isDeviceRegistered) {
      return (
        <>
          <Button color="success" size="small" onClick={() => props.callback()}>
            {Translate.translate("REFRESH_PAGE")}
          </Button>
        </>
      );
    }

    if (usr.user?.isActive === true) {
      return (
        <Button color="warning" onClick={() => activateToggle(usr.user?._id)}>
          {Translate.translate("DEACTIVATE")}
        </Button>
      );
    } else {
      return (
        <>
          <Button color="success" onClick={() => activateToggle(usr.user?._id)}>
            {Translate.translate("ACTIVATE")}
          </Button>
        </>
      );
    }
  }

  function newDevice() {
    if (props.user?.account.owner === props.user?.user?._id) {
      return false;
    } else {
      if (props.user?.user?.isDeviceRegistered) {
        return false;
      } else {
        return true;
      }
    }
  }

  function activateToggle(id) {
    UserService.activateToggle(id)
      .then((result) => {
        console.log(result);
        props.callback();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <TableRow key={props.index}>
      <TableCell>{props.index + 1}</TableCell>
      <TableCell>
        {props.user?.user?.deviceType === "Android" ? <Android /> : <Apple />}
      </TableCell>
      <TableCell>{props.user?.user?.first_name} {props.user?.user?.last_name} </TableCell>
      <TableCell>{props.user?.user?.email}</TableCell>
      <TableCell>{deviceName(props.user?.user) ?? "-"}</TableCell>
      <TableCell>{props.user?.user?.appversion}</TableCell>
      <TableCell>{props.user?.user?.deviceID}</TableCell>
      <TableCell align="right">
        <StyledInfo>
          {newDevice() && sessionStorage.getItem("isPremiumSubscriber") === "true" &&  (
            <>
              <center>
                <QRCode
                  size={250}
                  includeMargin={true}
                  value={
                    props.user?.user?.activation_code
                      ? props.user?.user?.activation_code
                      : ""
                  }
                />
                <Alert severity="warning">
                  {Translate.translate("QR_LOGIN_INFO")}
                </Alert>
              </center>
            </>
          )}

          <ButtonGroup sx={{ mt: 2 }} variant="text" size="small">
            {printQR(props.user)}
            {actionButtons(props.user)}
          </ButtonGroup>
        </StyledInfo>
      </TableCell>
    </TableRow>
  );
}
