import axios from "axios";
const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

function header() {
  const token = sessionStorage.getItem("token");
  return {
    headers: { Authorization: `Bearer ${token}` },
  };
}

function makeUrl(url) { 
  return `${REACT_APP_API_URL}/${url}`;
}

function Get(url, callback) {
  return axios.get(makeUrl(url), header());
}

function Delete(url, callback) {
  return axios.delete(makeUrl(url), header());
}

function Post(url, payload, callback) {
  return axios.post(makeUrl(url), payload, header());
}

function Put(url, payload, callback) {
  return axios.put(makeUrl(url), payload, header());
}

export const ServiceManager = {
  Get,
  Post,
  Put,
  Delete,
};
