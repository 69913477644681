const ProductsLang = {
  PRODUCTS: {
    en: "Products",
    tr: "Ürünler",
  },

  PRODUCTS_DETAILS: {
    en: "Product Details",
    tr: "Ürün Detayları",
  },
  PRODUCT_NAME: {
    en: "Product Name",
    tr: "Ürün Adı",
  }
};

export default ProductsLang;