import React from "react";
import moment from "moment";
import {
  Chip,
  Link,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import { Translate } from "../i18n/translate";
import PremiumFeatureRequiredView from "../helpers/premiumFeature";
import { useNavigate } from "react-router-dom";
const TransactionTable = (props) => {
  const navigate = useNavigate();

  return (
    <>
      {sessionStorage.getItem("isPremiumSubscriber") === "true" ? (
        <Table size="small">
          <TableHead>
            <TableRow>
        
              <TableCell align="right">{Translate.translate("QUANTITY")}</TableCell>
              <TableCell>{Translate.translate("DATE")}</TableCell>
              <TableCell>{Translate.translate("STOCK_NAME")}</TableCell>
              <TableCell>{Translate.translate("WAREHOUSE_NAME")}</TableCell>
              <TableCell>{Translate.translate("EXPLANATION")}</TableCell>
              <TableCell>{Translate.translate("USER")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.transactions.map((t, i) => (
              <TableRow key={i}>
               <TableCell align="right">
                  <Chip
                    size="small"
                    label={t.quantity.toLocaleString()}
                    color={t.quantity < 0 ? "error" : "success"}
                  />
                </TableCell>
                <TableCell>
                  {moment(t.createdAt).format("DD.MM.YYYY HH:mm")}
                </TableCell>
                <TableCell>
                  <Link
                    href="#"
                    onClick={() => navigate("/stocks/" + t.stock?._id)}
                  >
                    {t.stock?.stockName}
                  </Link>
                </TableCell>
                <TableCell>
                  <Link
                    href="#"
                    onClick={() =>
                      navigate(
                        `/warehouses/${
                          t.warehouse[0]
                            ? t.warehouse[0]?._id
                            : t.warehouse?._id
                        }`
                      )
                    }
                  >
                    {t.warehouse[0]?.warehouseName}
                    {t.warehouse?.warehouseName}
                  </Link>
                </TableCell>

                <TableCell>
                  {t.orderNo && (
                    <small>
                      {
                        <Link
                          sx={{ mr: 1 }}
                          href="#"
                          onClick={() =>
                            navigate(
                              `/orders/${
                                t.order
                              }`
                            )
                          }
                        >
                          Ref: {t.orderNo}
                        </Link>
                      }
                    </small>
                  )}
                  {t.description}
                </TableCell>
                <TableCell>{t.user?.first_name} {t.user?.last_name}</TableCell>
            

                
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow></TableRow>
          </TableFooter>
        </Table>
      ) : (
        <PremiumFeatureRequiredView />
      )}
    </>
  );
};
export default TransactionTable;
