import React, { useEffect } from "react";
import moment from "moment";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Chip,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { WarehouseService } from "../../services/warehouse.services";
import { confirmAlert } from "react-confirm-alert";
import { Translate } from "../../i18n/translate";
import PremiumFeatureRequiredView from "../../helpers/premiumFeature";
import NavBreadcrumbs from "../../sections/NavBreadcrumbs";
import TransactionTable from "../../sections/TransactionTable";

const ViewCase = () => {
  let { warehouseId } = useParams();
  const navigate = useNavigate();
  const [warehouse, setStock] = useState({});
  const [transactions, setTransactions] = useState([]);
  const [page, setpage] = useState(0);
  const navigations = {
    title: warehouse?.warehouseName,
    items: [
      { name: "WAREHOUSES", link: "/warehouses" },
      { name: warehouse?.warehouseName },
    ],
    navItems: [
      { id: "update", title: "UPDATE", image: "update.png" },
      { id: "inventory", title: "INVENTORY_LIST", image: "list.png" },
      { id: "delete", title: "DELETE", image: "trash.png" },
    ],
  };

  useEffect(() => {
    getStock();
    getTransactions();
  }, []);

  const getStock = async () => {
    WarehouseService.getOne(warehouseId)
      .then((result) => {
        setStock(result.data);
      })
      .catch((error) => {});
  };

  function getTransactions(page = 0) {
    WarehouseService.getTransaction(warehouseId, page, 10)
      .then((result) => {
        setTransactions(transactions.concat(result.data));
      })
      .catch((error) => {});
  }

  function loadmore() {
    let p = page + 1;
    setpage(p);
    getTransactions(p);
  }

  function deleteConfirm() {
    confirmAlert({
      title: "Uyarı",
      message: "Bu Depo ve tüm stok işlemleri silinecektir. Onaylıyor musunuz?",
      buttons: [
        {
          label: "Evet",
          onClick: () => deleteStock(),
        },
        {
          label: "Vazgeç",
        },
      ],
    });
  }
  function deleteStock() {
    WarehouseService.deleteOne(warehouseId)
      .then((result) => {
        navigate(`/warehouses`);
      })
      .catch((error) => {
        let errorMessage = error.message;
      });
  }
  function navBarAction(id) {
    if (id === "new") {
      navigate("/createWarehouse");
    }

    if (id === "update") {
      navigate(`/updateWarehouse/${warehouse?._id}`);
    }
    if (id === "delete") {
      deleteConfirm();
    }
    if (id === "inventory") {
      navigate(`/warehouses/${warehouse?._id}/inventory`);
    }
  }
  return (
    <>
      <NavBreadcrumbs navs={navigations} callback={navBarAction} />
      <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
        <Typography component="h2" variant="h6" color="primary" gutterBottom>
          {warehouse?.warehouseName},{" "}
          {Translate.translate("RECENT_TRANSACTIONS")}
        </Typography>

        <TransactionTable transactions={transactions} />
        {sessionStorage.getItem("isPremiumSubscriber") === "true" && (
            <Button size="small" onClick={() => loadmore()} sx={{ mt: 2 }}>
              {Translate.translate("MORE")}
            </Button>
          )}
      </Paper>
    </>
  );
};
export default ViewCase;
