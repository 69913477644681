import { ServiceManager } from "./serviceManager";

export const ProductService = {
  getAll,
  getOne,
  getTransaction,
  getQuantity,
  save,
  search,
  update,
  removeComponentItem,
  addStockToProduct,
  deleteStock,
  getStockQuantitiesByWeekday
};

function addStockToProduct(productId,payload) {
  return ServiceManager.Post(`/products/${productId}/addStock`, payload);
}


function getStockQuantitiesByWeekday(id) {
  return ServiceManager.Get(
    `products/${id}/getStockQuantitiesByWeekday`
  );
}
function getAll(page = 0, limit = 10) {
  return ServiceManager.Get(`products?limit=${limit}&page=${page}`);
}

function save(payload) {
  return ServiceManager.Post(`products`, payload);
}

// --------------------------------------
function getOne(id) {
  return ServiceManager.Get(`products/${id}`);
}

function getTransaction(id, page = 0, limit = 10) {
  return ServiceManager.Get(
    `products/${id}/transactions?limit=${limit}&page=${page}`
  );
}
function getQuantity(id) {
  return ServiceManager.Get(`products/${id}/quantity`);
}

function search(payload) {
  return ServiceManager.Post(`products/search`, payload);
}

function update(id, payload) {
  return ServiceManager.Put(`products/${id}`, payload);
}

function removeComponentItem(idProduct, idProductComponent) {
  return ServiceManager.Delete(`products/${idProduct}/components/${idProductComponent}`);
}

 

function deleteStock(id) {
  return ServiceManager.Delete(`products/${id}`);
}