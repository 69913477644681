import axios from "axios";
const REACT_APP_API_URL =
  process.env.REACT_APP_API_URL || "https://api.eltrm.com/v2";
const REACT_APP_VERSION = process.env.REACT_APP_VERSION || "v2";
 
 
function headers() {
  return {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      appversion: REACT_APP_VERSION,
    },
  };
}

function makeUrl(url) {
  return `${REACT_APP_API_URL}/${url}`;
}

function Get(url) {
  return axios.get(makeUrl(url), headers());
}

function Delete(url) {
  return axios.delete(makeUrl(url), headers());
}

function Post(url, payload) {
  return axios.post(makeUrl(url), payload, headers());
}

function Put(url, payload) {
  return axios.put(makeUrl(url), payload, headers());
}

export const ServiceManager = {
  Get,
  Post,
  Put,
  Delete,
};
