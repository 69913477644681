import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Grid,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Alert,
  TextField,
  Modal,
  Box,
} from "@mui/material";
import { UserService, AdminService } from "../../services";
import DeviceCard from "../../sections/DeviceCard";
import { Translate } from "../../i18n/translate";
import NavBreadcrumbs from "../../sections/NavBreadcrumbs";

export default function Warehouses() {
  const [users, setUsers] = useState([]);
  const [setAccount] = useState();
  const [numofdevices] = useState(sessionStorage.getItem("numofdevices") ?? 0);
  const [values, setValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const navigations = {
    title: "USERS",
    items: [{ name: "USERS" }],
    navItems: [{ id: "createUser", title: "CREATE_USER", image: "plus.png" }],
  };

  useEffect(() => {
    loadItems();
  }, []);

  const loadItems = async () => {
    try {
      const usersResponse = await UserService.getAll();
      setUsers(usersResponse.data);

      const accountResponse = await AdminService.getAccountInfo();

     
      sessionStorage.setItem("isPremiumSubscriber", accountResponse?.data?.isPremiumSubscriber );
      sessionStorage.setItem("numofdevices", accountResponse?.data?.numofdevices);
      

      setAccount(accountResponse.data);
    } catch (error) {
      // Handle error if needed
    }
  };

  const handleInputChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const saveUser = async () => {
    if (isLoading) {
      return;
    }

    if (!values.firstName || !values.lastName) {
      return;
    }

    try {
      await UserService.save({
        first_name: values.firstName,
        last_name: values.lastName,
      });

      setIsLoading(false);
      setOpen(false);
      loadItems();
    } catch (error) {
      // Handle error if needed
    }
  };

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "0px solid #000",
    p: 4,
  };

  const [open, setOpen] = useState(false);
  function navBarAction(id) {
    if (id === "createUser") {
      setOpen(true);
    }
  }
  return (
    <>
      <NavBreadcrumbs navs={navigations} callback={navBarAction} />
      <Modal
        open={open}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography sx={{ mb: 2 }} variant="h5">
            {Translate.translate("CREATE_USER")}
          </Typography>

          <TextField
            sx={{ mb: 3 }}
            label={Translate.translate("FIRST_NAME")}
            fullWidth
            value={values.firstName || ""}
            onChange={handleInputChange("firstName")}
          />

          <TextField
            sx={{ mb: 3 }}
            label={Translate.translate("LAST_NAME")}
            fullWidth
            value={values.lastName || ""}
            onChange={handleInputChange("lastName")}
          />

          <Alert severity="info">
            {Translate.translate("CREATE_USER_INFO_TEXT")} {users.length} /{" "}
            {numofdevices}
          </Alert>

          {users.length < numofdevices && (
            <Button
              sx={{ mt: 2 }}
              disabled={isLoading}
              variant="contained"
              onClick={async () => {
                setIsLoading(true);
                await saveUser();
              }}
            >
              {Translate.translate("SAVE")}
            </Button>
          )}
        </Box>
      </Modal>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <Typography variant="h6">{Translate.translate("USERS")}</Typography>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell> </TableCell>
                  <TableCell>{Translate.translate("USER")}</TableCell>
                  <TableCell>{Translate.translate("EMAIL")}</TableCell>
                  <TableCell>{Translate.translate("DEVICE")}</TableCell>
                  <TableCell>{Translate.translate("APP_VERSION")}</TableCell>
                  <TableCell>{Translate.translate("DEVICE_ID")}</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((post, index) => (
                  <DeviceCard
                    callback={() => {
                      loadItems();
                    }}
                    key={post._id}
                    user={post}
                    index={index}
                  />
                ))}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
