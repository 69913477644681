import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TransactionService } from "../../services/transaction.services";
import { ProductService } from "../../services/product.services";
import { StockService } from "../../services/stock.services";
import { AlertDialog } from "../../helpers/helper.alertDialog";
import { Translate } from "../../i18n/translate";
import SearchBox from "../../sections/SearchBox";
import NavBreadcrumbs from "../../sections/NavBreadcrumbs";
import QRCode from "react-qr-code";
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import { AddCircle, Clear } from "@mui/icons-material";
import { NumericFormat } from "react-number-format";

const TransactionPage = () => {
  const navigate = useNavigate();
  const { productId } = useParams();
  const [stocks, setStocks] = useState([]);
  const [product, setProduct] = useState();
  const [transactions, setTransactions] = useState([]);

  const [selectedWarehouse, setSelectedWarehouse] = useState();
  const [transactionType, setTransactionType] = useState("export");
  const [description, setDescription] = useState("");
  const [itemQuantities, setItemQuantities] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const result = await ProductService.getOne(productId);
      setProduct(result.data);
    } catch (error) {
      console.error("Error fetching warehouses:", error);
    }
  };

  const handleSearch = (e) => {
    const searchText = e.target.value;
    if (searchText === "") {
      setStocks([]);
      return;
    }
    if (
      sessionStorage.getItem("isPremiumSubscriber") === "true" ||
      e.key === "Enter"
    ) {
      executeSearch(searchText);
    }
  };

  const executeSearch = async (searchText) => {
    if (searchText === "") {
      setStocks([]);
      return;
    }
    try {
      const result = await StockService.search({ searchText });
      setStocks(result.data);
    } catch (error) {
      console.error("Error during search:", error);
    }
  };

  const addItemToTransactions = async (item) => {
    const quantity =
      itemQuantities[item._id] > 0 ? itemQuantities[item._id] : 1;

    try {
      await ProductService.addStockToProduct(productId, {
        stock: item._id,
        quantity,
      });
      await fetchProducts();
    } catch (error) {
      console.error("Error adding stock to product:", error);
    }
  };

  const removeStockFromComponents = (id) => {
    try {
      ProductService.removeComponentItem(productId, id);
      fetchProducts();
    } catch (error) {
      console.error("Error fetching warehouses:", error);
    }
  };

  const handleQuantityChange = (e) => {
    setItemQuantities({ ...itemQuantities, [e.target.name]: e.target.value });
  };

  const createTransaction = async () => {
    setLoading(true);
    const finalPayload = transactions?.map((transaction) => ({
      stock: transaction._id,
      warehouse: selectedWarehouse,
      quantity:
        transactionType === "export"
          ? -transaction.quantity
          : transaction.quantity,
      description,
    }));

    try {
      await TransactionService.create(finalPayload);
      setStocks([]);
      setTransactions([]);
      AlertDialog.showSuccess(Translate.translate("OPERATION_IS_SUCCESSFUL"));
    } catch (error) {
      console.error("Error creating transaction:", error);
    } finally {
      setLoading(false);
    }
  };

  const calculateGrandTotal = () => {
    return transactions.reduce(
      (total, transaction) =>
        total + transaction.salePrice * transaction.quantity,
      0
    );
  };

  const navigations = {
    title: product?.name,
    items: [
      { name: "Products", link: "/products" },
      { name: product?.productNo },
    ],
    navItems: [
      { id: "new", title: "UPDATE", image: "update.png" },
      // { id: "importFromExcel", title: "IMPORT_FROM_EXCEL", image: "excel.png" },
    ],
  };

  function navBarAction(action) {
    if (action === "new") {
      navigate("/products/update/" + productId);
      return;
    } else if (action === "importFromExcel") {
      navigate("/import");
      return;
    }
  }

  return (
    <>
       <NavBreadcrumbs navs={navigations} callback={navBarAction} />

      <Grid container spacing={2}>
        <Grid item xs={2} lg={1}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <QRCode
              id="QRCode"
              size={256}
              style={{
                height: "auto",
                maxWidth: "100%",
                width: "100%",
                cursor: "copy",
              }}
              value={product?.productNo || "no"}
              viewBox={`0 0 256 256`}
            />
            <center>{product?.productNo}</center>
          </Paper>
        </Grid>

        <Grid item xs={10} lg={5}>
          <Paper sx={{ p: 2, mb: 5 }}>
            <Typography
              component="h2"
              variant="h6"
              color="primary"
              sx={{ mb: 3 }}
            >
              {Translate.translate("PRODUCTS_DETAILS")}
            </Typography>

            {product?.name}
          </Paper>
        </Grid>

        <Grid item xs={12} lg={6}>
      
          
          <SearchBox callback={executeSearch} onCancel={() => setStocks([])} />
 
          <Grid item sx={{ mt: 3 }}>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
              <Typography
                component="h2"
                variant="h6"
                color="primary"
                gutterBottom
              >
                {Translate.translate("STOCKS")}
              </Typography>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>{Translate.translate("STOCK_NAME")}</TableCell>
                    <TableCell>{Translate.translate("STOCK_CODE")}</TableCell>
                    <TableCell>{Translate.translate("BARCODE")}</TableCell>
                    <TableCell>{Translate.translate("QUANTITY")}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stocks?.map((stock) => (
                    <TableRow key={stock._id}>
                      <TableCell>{stock.stockName}</TableCell>
                      <TableCell>{stock.stockCode}</TableCell>
                      <TableCell>{stock.barcode}</TableCell>
                      <TableCell>
                        <TextField
                          type="number"
                          size="small"
                          name={stock._id}
                          value={itemQuantities[stock._id] || 1}
                          onChange={handleQuantityChange}
                        />
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => addItemToTransactions(stock)}
                          color="success"
                        >
                          <AddCircle />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <Typography
              component="h2"
              variant="h6"
              color="primary"
              gutterBottom
            >
              {Translate.translate("STOCKS")}
            </Typography>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>{Translate.translate("STOCK_NAME")}</TableCell>
                  <TableCell>{Translate.translate("STOCK_CODE")}</TableCell>
                  <TableCell>{Translate.translate("BARCODE")}</TableCell>
                  <TableCell>{Translate.translate("SALE_PRICE")}</TableCell>
                  <TableCell>{Translate.translate("QUANTITY")}</TableCell>
                  <TableCell>{Translate.translate("TOTAL_VALUE")}</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {product?.components?.map((row, index) => (
                  <TableRow key={row._id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{row.stock?.stockName}</TableCell>
                    <TableCell>{row.stock?.stockCode}</TableCell>
                    <TableCell>{row.stock?.barcode}</TableCell>
                    <TableCell>
                      <NumericFormat
                        value={row.stock?.salePrice}
                        displayType={"text"}
                        thousandSeparator={true}
                        suffix={"₺"}
                      />
                    </TableCell>
                    <TableCell>{row.quantity}</TableCell>
                    <TableCell>
                      <NumericFormat
                        value={row.stock?.salePrice * row.quantity}
                        displayType={"text"}
                        thousandSeparator={true}
                        suffix={"₺"}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        size="small"
                        onClick={() => removeStockFromComponents(row._id)}
                        color="error"
                      >
                        <Clear />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={5}>
                    {Translate.translate("GRAND_TOTAL")}
                  </TableCell>
                  <TableCell>
                    <NumericFormat
                      value={calculateGrandTotal()}
                      displayType={"text"}
                      thousandSeparator={true}
                      suffix={"₺"}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default TransactionPage;
