import { Routes, Route } from "react-router-dom";
import SharedLayout from "./layouts/SharedLayout";
import RequireAuth from "./pages/RequireAuth";
import Login from "./pages/Login";
import Error from "./pages/Error";
import HomePage from "./pages/HomePage";
import Stocks from "./pages/stocks/stocks";
import Products from "./pages/products/products";
import ViewProduct from "./pages/products/ViewProduct";
import UpdateProduct from "./pages/products/UpdateProduct";
import ViewStock from "./pages/stocks/ViewStock";
import UpdateStock from "./pages/stocks/UpdateStock";
import UpdateWarehouse from "./pages/warehouses/UpdateWarehouse";
import Users from "./pages/users/Users";
import Reports from "./pages/reports/Reports";
import InventoryReport from "./pages/reports/InventoryReport";
import InventoryQuantityStatusReport from "./pages/reports/inventoryQuantityStatusReport";

import Settings from "./pages/settings/settings";
import Warehouses from "./pages/warehouses/warehouses";
import Transactions from "./pages/transactions/transactions";
import ViewWarehouse from "./pages/warehouses/ViewWarehouse";
import WarehouseInventory from "./pages/warehouses/WarehouseInventory";
import Integrations from "./pages/integrations/integrations";
import Import from "./pages/stocks/import";
import "./App.css";
import { useState } from "react";
import { IntlProvider } from "react-intl";
import { LangContext } from "./i18n/LangContext";
// Modules
import Customers from "./pages/customers/customers";
import UpdateCustomer from "./pages/customers/UpdateCustomer";

import ViewCustomer from "./pages/customers/ViewCustomer";
import Orders from "./pages/orders/orders";
import ViewOrder from "./pages/orders/ViewOrder";

function App() {
  const [langContext, setLangContext] = useState(
    sessionStorage.getItem("locale")
  );

  return (
    <LangContext.Provider value={{ langContext, setLangContext }}>
      <IntlProvider locale={langContext}>
        <Routes>
          {/* Public */}
          <Route path="login" element={<Login />} />
          {/* Protected */}
          <Route element={<RequireAuth />}>
            <Route path="/" element={<SharedLayout />}>
              <Route index element={<HomePage />} />
              <Route path="users" element={<Users />} />
              <Route path="settings" element={<Settings />} />
              <Route path="import" element={<Import />} />
              <Route path="integrations" element={<Integrations />} />
              <Route path="createStock" element={<UpdateStock />} />
              <Route path="updateStock/:stockId" element={<UpdateStock />} />
              <Route path="createWarehouse" element={<UpdateWarehouse />} />
              <Route
                path="updateWarehouse/:warehouseId"
                element={<UpdateWarehouse />}
              />
              <Route path="customers">
                <Route index element={<Customers />} />
                <Route path=":customerId" element={<ViewCustomer />} />
                <Route path="create" element={<UpdateCustomer />} />
                <Route path="update/:customerId" element={<UpdateCustomer />} />
              </Route>
              <Route path="orders">
                <Route index element={<Orders />} />
                <Route path=":orderId" element={<ViewOrder />} />
              </Route>
              <Route path="stocks">
                <Route index element={<Stocks />} />
                <Route path=":stockId" element={<ViewStock />} />
              </Route>4
              <Route path="products">
                <Route index element={<Products />} />
                <Route path=":productId" element={<ViewProduct />} />
                <Route path="update/:productId" element={<UpdateProduct />} />
                <Route path="create" element={<UpdateProduct />} />
              </Route>
              <Route path="warehouses">
                <Route index element={<Warehouses />} />
                <Route path=":warehouseId" element={<ViewWarehouse />} />
                <Route
                  path=":warehouseId/inventory"
                  element={<WarehouseInventory />}
                />
              </Route>
              <Route path="transactions">
                <Route index element={<Transactions />} />
              </Route>

              <Route path="reports">
                <Route index element={<Reports />} />
                <Route
                  path="inventoryQuantityStatusReport"
                  element={<InventoryQuantityStatusReport />}
                />
                <Route path="inventoryReport" element={<InventoryReport />} />
              </Route>
              <Route path="*" element={<Error />} />
            </Route>
          </Route>
          {/* catch all */}
        </Routes>
      </IntlProvider>
    </LangContext.Provider>
  );
}

export default App;
