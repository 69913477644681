import React from "react";
import {
  Card,
  Grid,
  Typography,
  CardContent,
  CardActionArea,
  Chip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Translate } from "../i18n/translate";
import { NumericFormat } from "react-number-format";
import OrderStatus from "../helpers/OrderStatus";

export default function OrderCard(props) {
  const navigate = useNavigate();
  const handleclick = () => navigate(`/orders/${props.order._id}`);

  const { order } = props;
  const { orderDate, customer, orderNo, grandTotal, warehouse } = order;

  const orderStatus = OrderStatus.getStatusFromValue(order.orderStatus);
  const orderStatusColor = OrderStatus[orderStatus].color;

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Card
        sx={{
          maxWidth: 345,
          borderRadius: 2,
          boxShadow: 3,
         
        }}
      >
        <CardActionArea onClick={handleclick}>
          <CardContent>
 

            <Typography variant="h6" component="div" sx={{ fontWeight: "bold", mb: 1 }}>
              {orderNo}
            </Typography>

            <Typography color="text.secondary" sx={{ mb: 0.5 }}>
              📅 {moment(orderDate).format("DD. MMMM YYYY")}
            </Typography>

            <Typography color="text.secondary" sx={{ mb: 1 }}>
              🥷 {customer && customer[0]?.firstName} {customer && customer[0]?.lastName}
            </Typography>

            {warehouse && (
              <Typography color="text.secondary" sx={{ mb: 1 }}>
                🚛 {warehouse[0]?.warehouseName}
              </Typography>
            )}

            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                color: "primary.main",
                display: "flex",
                alignItems: "center",
              }}
            >
              <NumericFormat
                value={grandTotal}
                displayType="text"
                thousandSeparator
                prefix="💰 "
                renderText={(formattedValue) => formattedValue}
              />
            </Typography>

            {/* Optional Order Status Chip (could be added for visual clarity) */}
            <Chip
              label={Translate.translate(orderStatus)}
              color="primary"
              sx={{
                mt: 2,
                fontWeight: "bold",
                textTransform: "capitalize",
                fontSize: 12,
              }}
            />
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
}
