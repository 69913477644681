import {
  Box,
  Card,
  Grid,
  Typography,
  CardContent,
  CardActionArea,
  CardMedia,
  CardActions,
} from "@mui/material";
import {
  ArrowDownward,
  ArrowUpward,
  HourglassBottom,
  NotificationImportant,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { NumericFormat } from "react-number-format";

export default function StockCard({ post, index }) {
  const navigate = useNavigate();

  const color = post.totalQuantity < post.notificationLimit ? "red" : "inherit";

  const totalQuantity = post.totalQuantity ?? 0;
  const shouldHideQuantity = totalQuantity === 0;

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Card
        sx={{
          maxWidth: 345,
          borderRadius: 3,
          boxShadow: 4,
          transition: "transform 0.2s ease-in-out",
          "&:hover": { transform: "scale(1.03)" },
        }}
      >
        <CardActionArea onClick={() => navigate(`/stocks/${post._id}`)}>
          <CardMedia
            component="img"
            height="160"
            image={
              post.images?.length > 0
                ? `${process.env.REACT_APP_API_URL}/images/${post.images[0].image}`
                : `${process.env.PUBLIC_URL}/noimg.jpg`
            }
            alt={post.stockName || "Stock Image"}
          />
          <CardContent sx={{ textAlign: "center", p: 2 }}>
            <Typography
              sx={{ fontSize: 14, fontWeight: 500, color: "gray" }}
              gutterBottom
            >
              {post.barcode}
            </Typography>
            <Typography variant="h5" fontWeight="bold" sx={{ color: "#1976d2" }}>
              {post.stockName}
            </Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {post.stockCode}
            </Typography>
          </CardContent>

          <CardActions sx={{ background: "linear-gradient(135deg, #e3f2fd, #ffffff)", p: 1 }}>
            <Box sx={{ display: "flex", alignItems: "center", mr: 2 }}>
              <NotificationImportant sx={{ width: 18, height: 18, mr: 0.5, color: "#d32f2f" }} />
              <Typography variant="caption" fontWeight="bold">
                {post.notificationLimit ?? 0}
              </Typography>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center", mr: 2 }}>
              <ArrowDownward sx={{ width: 18, height: 18, mr: 0.5, color: "#388e3c" }} />
              <Typography variant="caption" fontWeight="bold">
                <NumericFormat
                  decimalScale={2}
                  value={post.purchasePrice ?? 0}
                  displayType={"text"}
                  thousandSeparator={true}
                  suffix={"₺"}
                />
              </Typography>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center", ml: 2 }}>
              <ArrowUpward sx={{ width: 18, height: 18, mr: 0.5, color: "#f57c00" }} />
              <Typography variant="caption" fontWeight="bold">
                <NumericFormat
                  decimalScale={2}
                  value={post.salePrice ?? 0}
                  displayType={"text"}
                  thousandSeparator={true}
                  suffix={"₺"}
                />
              </Typography>
            </Box>

            {!shouldHideQuantity && (
              <Box sx={{ display: "flex", alignItems: "center", ml: 2 }}>
                <HourglassBottom sx={{ width: 18, height: 18, mr: 0.5, color: color }} />
                <Typography variant="caption" sx={{ fontWeight: "bold", color: color }}>
                  <NumericFormat
                    decimalScale={2}
                    value={post.totalQuantity ?? 0}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </Typography>
              </Box>
            )}
          </CardActions>
        </CardActionArea>
      </Card>
    </Grid>
  );
}
