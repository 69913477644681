import React, { useMemo } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import jwtDecode from "jwt-decode";

const TOKEN_KEY = "token";

// Helper functions for sessionStorage
const getToken = () => sessionStorage.getItem(TOKEN_KEY);
const removeToken = () => sessionStorage.removeItem(TOKEN_KEY);

const isTokenValid = (token) => {
  if (!token) return false;
  try {
    const decodedToken = jwtDecode(token);
    return decodedToken.exp >= Date.now() / 1000;
  } catch (error) {
    console.error("Error decoding token:", error);
    return false;
  }
};

const RequireAuth = () => {
  const location = useLocation();
  const token = getToken();

  // Decode token only once and memoize the result
  const decodedToken = useMemo(() => {
    try {
      return token ? jwtDecode(token) : null;
    } catch (error) {
      console.error("Error decoding token:", error);
      return null;
    }
  }, [token]);

  if (!isTokenValid(token)) {
    removeToken();
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // Store additional user data if not in development mode
  if (process.env.NODE_ENV !== "development" && decodedToken) {
    sessionStorage.setItem("isPremiumSubscriber", decodedToken.isPremiumSubscriber);
    sessionStorage.setItem("numofdevices", decodedToken.numofdevices);
  }

  return <Outlet />;
};

export default RequireAuth;
