import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Breadcrumbs,
  Button,
  ButtonGroup,
  Grid,
  Link,
  Paper,
  Typography,
} from "@mui/material";
import { ProductService } from "../../services/product.services";
import StockCard from "../../sections/StockCard";
import { Translate } from "../../i18n/translate";
import SearchBox from "../../sections/SearchBox";
import NavBreadcrumbs from "../../sections/NavBreadcrumbs";
import ProductCard from "../../sections/ProductCard";

import PremiumFeatureRequiredView from "../../helpers/premiumFeature";

export default function CustomPaginationActionsTable() {
  const navigate = useNavigate();
  const [stocks, setStocks] = useState([]);
  const [page, setPage] = useState(0);
  const navigations = sessionStorage.getItem("isPremiumSubscriber") === "true" ?  {
    title: "PRODUCTS",
    items: [{ name: "PRODUCTS" }],
    navItems: [ 
      { id: "new", title: "NEW", image: "plus.png" }
    ],
  }: {}

  useEffect(() => {
    loadItems();
  }, [page]);

  const loadItems = async () => {
    try {
      const result = await ProductService.getAll(page, 20);
      setStocks((prevStocks) => [...prevStocks, ...result.data]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const makeSearch = async (searchText) => {
    try {
      const result = await ProductService.search({ searchText });
      setStocks(result.data);
    } catch (error) {
      console.error("Error searching stocks:", error);
    }
  };

  const loadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
  };

  function navBarAction(action) {
    if (action === "new") {
      navigate("/products/create");
      return;
    } else if (action === "importFromExcel") {
      navigate("/import");
      return;
    }
  }

  return (
    <>
      <NavBreadcrumbs navs={navigations} callback={navBarAction} />


      {sessionStorage.getItem("isPremiumSubscriber") === "true" ? (
        <>
              <SearchBox
        callback={(searchText) => makeSearch(searchText)}
        onCancel={async () => {
          setPage(0);
          setStocks([]);
          loadItems();
        }}
      />
      
          <Grid sx={{ mt: 3 }} container spacing={3}>
            {stocks.map((post, index) => (
              <ProductCard key={post._id} post={post} index={index} />
            ))}
          </Grid>

          <Button color="primary" onClick={loadMore} sx={{ mt: 2 }}>
        {Translate.translate("MORE")}
      </Button>
        </>
      ) : (

   
          <PremiumFeatureRequiredView />
         
  
        
      )}


    </>
  );
}
