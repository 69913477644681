import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid, Paper, TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { ProductService } from "../../services/product.services";
import { Translate } from "../../i18n/translate";
import NavBreadcrumbs from "../../sections/NavBreadcrumbs";
import { AlertDialog } from "../../helpers/helper.alertDialog";

export default function UpdateStock() {
  const { productId } = useParams();
  const navigate = useNavigate();
  const [values, setValues] = useState({
    name: "",
  });

  useEffect(() => {
    if (productId !== undefined) {
      fetchStock();
    }
  }, []);

  const navigations = {
    title: productId === undefined ? "CREATE_PRODUCT" : "UPDATE_PRODUCT",
    items: [
      { name: "PRODUCTS", link: "/products" },
      // { name: values.stockName, link: "/products/" + productId },
      { name: productId === undefined ? "CREATE_PRODUCT" : "UPDATE_PRODUCT" },
    ],
    navItems: [{ id: "save", title: "SAVE", image: "save.png" }],
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  async function fetchStock() {
    try {
      const result = await ProductService.getOne(productId);
      setValues(result.data);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message?.message ||
        "An error occurred while fetching stock data.";
      AlertDialog.showAlert(errorMessage);
    }
  }

  async function submit() {
    try {
      if (productId === undefined) {
        const saveResult = await ProductService.save(values);
        const newproductId = saveResult.data._id;
        navigate(`/products/${newproductId}`);
      } else {
        let payload = {
          name: values.name,
        };
        await ProductService.update(productId, payload);
        navigate(`/products/${productId}`);
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "An error occurred while processing your request.";

      AlertDialog.showAlert(Translate.translate("ERROR"), errorMessage);
    }
  }

  function navBarAction(action) {
    const { name } = values;

    if (action === "save" && name?.length > 0 ) {
      submit();
    }
  }

  return (
    <>
      <NavBreadcrumbs navs={navigations} callback={navBarAction} />
      <Paper sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <TextField
              error={values?.name?.length === 0}
             
              label={Translate.translate("PRODUCT_NAME")}
              id="outlined-start-adornment"
              fullWidth
              value={values?.name}
              onChange={handleChange("name")}
            />
          </Grid>

          
        </Grid>
      </Paper>
    </>
  );
}
