import React from "react";
import {
  Card,
  Grid,
  Typography,
  CardContent,
  CardActionArea,
  CardActions,
  Chip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Phone, Email, LocationOn } from "@mui/icons-material";

export default function CustomerCard(props) {
  const navigate = useNavigate();
  const handleclick = () => navigate(`/customers/${props.customer._id}`);

  const { customer } = props;

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Card
        sx={{
          maxWidth: 345,
          borderRadius: 2,
          boxShadow: 3,
          transition: "transform 0.3s ease, box-shadow 0.3s ease",
          "&:hover": {
            transform: "scale(1.05)",
            boxShadow: 8,
          },
        }}
      >
        <CardActionArea onClick={handleclick}>
          <CardContent>
            <Typography variant="h5" component="div" sx={{ fontWeight: "bold", mb: 1 }}>
              {customer?.firstName} {customer?.lastName}
            </Typography>

            <Typography sx={{ mb: 2 }} color="text.secondary">
              <Email sx={{ verticalAlign: "middle", mr: 0.5 }} />
              {customer?.email}
            </Typography>

            <Typography sx={{ mb: 2 }} color="text.secondary">
              <Phone sx={{ verticalAlign: "middle", mr: 0.5 }} />
              {customer?.phone}
            </Typography>

            <Typography sx={{ mb: 1.5 }} variant="body2" color="text.secondary">
              <LocationOn sx={{ verticalAlign: "middle", mr: 0.5 }} />
              {customer?.billing_address?.address}{" "}
              {customer?.billing_address?.postCode} <br />
              {customer?.billing_address?.city} {customer?.billing_address?.country}
            </Typography>
 
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
}
